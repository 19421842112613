// const domain: string = 'http://192.168.0.245:5000'
// export const domain: string = 'http://192.168.0.107:8001/api/v1'
export const domain: string = 'https://api.admin.joit.info/api/v1'

const apis = {
    test: `${ domain }/account/auth/refresh_access_token`,
    verifyAuthorization: `${ domain }/verify/authorization`,
    login: `${ domain }/login`,
    all_categories: `${ domain }/article/category/category`,
    get_essay_page: (page: number, limit: number) => `${ domain }/essay/${ page }/${ limit }`,
    get_essay: (essay_id: number) => `/essay/${ essay_id }`,
    create_essay: `${ domain }/essay`,
    update_essay: `${ domain }/essay`,
    remove_essay: (essay_id: number) => `${ domain }/essay/${ essay_id }`
}

export default apis

import { ILoginAction, ILoginState } from '@libs/interfaces'

import { ActionTypes } from '@libs/constants'
import { isAuthenticated } from '@libs/session'

const defaultState: ILoginState = {
    isFetching: false,
    isLoginFailed: false,
    isAuthenticated
}

const login = (state = defaultState, action: ILoginAction) => {
    switch (action.type) {
        case ActionTypes.LoginRequest:
            return { ...state, isFetching: true }
        case ActionTypes.LoginResponse:
            return { ...state, isFetching: false }
        case ActionTypes.LoginFailed:
            return { ...state, isLoginFailed: true, isFetching: false }
        case ActionTypes.Relogin:
            return { ...state, isLoginFailed: false }
        case ActionTypes.SetCurrentUser:
            return { ...state, isAuthenticated }
        default:
            return state
    }
}

export default login

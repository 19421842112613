import API from '@libs/apis'
import { ActionCreator } from 'redux'
import { ActionTypes } from '@libs/constants'
import { Dispatch } from 'redux'
import { LocalStorageKeys } from '@libs/constants'
import axios from '@libs/requests'
import { setAxiosAuthorization } from '@libs/common'

export const request = () => ({
    type: ActionTypes.LoginRequest
})

export const response = () => ({
    type: ActionTypes.LoginResponse
})

export const invalidLogin = () => ({
    type: ActionTypes.LoginFailed
})

export const relogin = () => ({
    type: ActionTypes.Relogin
})

export const setCurrentUser = (user: string) => ({
    type: ActionTypes.SetCurrentUser,
    user
})

export const verifyAuthorization: ActionCreator<any> = () => {
    return async (dispatch: Dispatch) => {
        const result = await axios.get(API.verifyAuthorization).catch(error => {
            console.log('No auth', error)
        })
        console.log(result)
    }
}

export const logout: ActionCreator<any> = () => {
    return async (dispatch: Dispatch) => {
        localStorage.removeItem(LocalStorageKeys.AccessToken)
        localStorage.removeItem(LocalStorageKeys.RefreshToken)
        setAxiosAuthorization('')
        dispatch(setCurrentUser(''))
    }
}

export const login: ActionCreator<any>  = (adminName: string, password: string) => {
    return async (dispatch: any) => {
        dispatch(request())
        const result = await axios.post(API.login, {
            admin_name: adminName,
            password
        }).catch(error => {
            dispatch(invalidLogin())
            console.log('Login failed, ', error)
        })

        if (!result) {
            return
        }

        const { data } = result

        dispatch(response())

        localStorage.setItem(LocalStorageKeys.AccessToken, data.access_token)
        setAxiosAuthorization(data.access_token)
        dispatch(setCurrentUser(adminName))

        return result
    }
}

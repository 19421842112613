import { applyMiddleware, compose, createStore } from 'redux'

import { combineReducers } from 'redux'
import { createLogger } from 'redux-logger'
import essayEdit from '@reducers/essay-edit-reducer'
import essayPage from '@reducers/essay-page-reducer'
import login from '@reducers/login-reducer'
import thunk from 'redux-thunk'

const reducers = combineReducers({
    login,
    essayEdit,
    essayPage
})

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(
            thunk,
            createLogger()
        )
    )
)

export default store

import '@src/index.less'

import * as serviceWorker from './serviceWorker'

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import store from '@store/index'

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark'
    }
})

ReactDOM.render(
    <Provider store={ store }>
        <BrowserRouter>
            <ThemeProvider theme={ darkTheme }>
                <App />
            </ThemeProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import { isEmpty } from 'lodash'
import React from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'

import { isAuthenticated } from '@libs/session'

const PrivateRoute = ({ component, ...rest }: any) => {
    const routeComponent = (props: any) => (
        !isEmpty(isAuthenticated())
            ? <>
                { React.createElement(component, props) }
              </>
            : <Redirect to={'/login'} />
    )

    return <Route { ...rest } render={ routeComponent } />
}

export default withRouter(PrivateRoute)
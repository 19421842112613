import React from 'react'

import { ConfirmationDialog, ConfirmationOptions } from '@components/confirm/confirm-dialog'
import { IConfirmationServiceProviderProps } from '@libs/interfaces'

const ConfirmationServiceContext = React.createContext<
  (options: ConfirmationOptions) => Promise<void>
>(Promise.reject)

export const useConfirmation = () => React.useContext(ConfirmationServiceContext)

export const ConfirmationServiceProvider = (props: IConfirmationServiceProviderProps) => {
  const [
    confirmationState,
    setConfirmationState
  ] = React.useState<ConfirmationOptions | null>(null)

  const [open, setOpen] = React.useState(false)

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void
    reject: () => void
  }>()

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options)
    setOpen(true)
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject }
    })
  }

  const handleClose = () => {
    setOpen(false)
    if (confirmationState!.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }
  }

  const handleSubmit = () => {
    setOpen(false)
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

  }

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={props.children}
      />

      <ConfirmationDialog
        open={open}
        onSubmit={handleSubmit}
        onClose={handleClose}
        variant="info"
        title="title"
        description="description"
        {...confirmationState}
      />
    </>
  )
}

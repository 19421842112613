import 'nprogress/nprogress.css'

import NProgress from 'nprogress'
import React from 'react'
import Loadable from 'react-loadable'

class ComponentLoading extends React.Component {
    public UNSAFE_componentWillMount() {
        NProgress.start()
    }

    public componentWillUnmount() {
        NProgress.done()
    }

    public render() {
        return (
            <div id="lazy-loading" />
        )
    }
  }

const Lazy = (component: () => any) => {
    return Loadable({
        loader: component,
        loading: () => <ComponentLoading />
    })
}

export default Lazy
import { ActionTypes, PageLimit } from '@libs/constants'
import { IEssayPageAction, IEssayPageState } from '@libs/interfaces'

const defaultState: IEssayPageState = {
    isFetching: false,
    data: [],
    count: 0,
    page: 1,
    limit: PageLimit
}

const essayPage = (state = defaultState, action: IEssayPageAction) => {
    switch (action.type) {
        case ActionTypes.GetEssayPageRequest:
            return { ...state, isFetching: true }
        case ActionTypes.GetEssayPageResponse: {
            const { data, count, limit, page} = action
            return {
                ...state,
                isFetching: false,
                data,
                count,
                limit,
                page
            }
        }
        case ActionTypes.GetEssayPageFailed:
            return { ...state, isFetching: false }
        default:
            return state
    }
}

export default essayPage

import { Redirect, Route, Switch, withRouter } from 'react-router-dom'

import Lazy from '@src/components/router/lazy'
import PrivateRoute from '@components/router/private-route'
import PrivateRouteEdit from '@components/router/private-route-edit'
import React from 'react'

const Dashboard = Lazy(() => import('@pages/dashboard/dashboard'))
const Login = Lazy(() => import('@pages/login/login'))
const EssayList = Lazy(() => import('@pages/essay/index'))
const EssayEdit = Lazy(() => import('@pages/essay/edit'))

const Routes: React.FC = () => (
    <Switch>
        <Route exact component={ Login } path={ '/login' } />
        <PrivateRoute exact component={ Dashboard } path={ '/' } />
        <PrivateRoute exact component={ EssayList } path={ '/essay/:page' } />
        <PrivateRouteEdit exact component={ EssayEdit } path={['/essay/edit/new', '/essay/edit/:essay_id']} />
        <Redirect from={'/essay'} to={ '/essay/1' } />
        <Redirect from={'*'} to={ '/' } />
    </Switch>
)

export default withRouter(Routes)

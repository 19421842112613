import { getAccessToken, isAuthenticated } from '@libs/session'

import Axios from 'axios'
import { AxiosError } from 'axios'
import { createBrowserHistory } from 'history'
import { domain } from '@libs/apis'
import { logout } from '@actions/login-action'
import store from '@store/index'

Axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const http =  Axios.create({
    baseURL: domain,
    timeout: 10000
})

http.interceptors.request.use(
    config => {
        if (isAuthenticated()) {
            config.headers['Authorization'] = 'Bearer ' + getAccessToken()
        }
        return config
    },
    error => {
        console.log('axios request error: ', error)
        Promise.reject(error)
    }
)

http.interceptors.response.use(
    res => {
        return res
    },
    (error: AxiosError) => {
        const http_status = error.response!.status
        if (http_status === 403) {
            store.dispatch(logout()).then(() => {
                const history = createBrowserHistory({
                    forceRefresh: true
                })
                history.replace('/login')
            })
        }
        return Promise.reject(error)
    }
)

export default http

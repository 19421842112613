export const PageLimit = 10

export const ToastDuration = 1500

export const LocalStorageKeys = {
    AccessToken: 'AccessToken',
    RefreshToken: 'RefreshToken'
}

export const ActionTypes = {
    LoginRequest: 'LOGIN_REQUEST',
    LoginResponse: 'LOGIN_RESPONSE',
    LoginFailed: 'LOGIN_FAILED',
    Relogin: 'RELOGIN',
    SetCurrentUser: 'SET_CURRENT_USER',
    EssayDetailEdit: 'ESSAY_DETAIL_EDIT',
    EssayTitleEdit: 'ESSAY_TITLE_EDIT',

    GetEssayPageRequest: 'GET_ESSAY_PAGE_REQUEST',
    GetEssayPageResponse: 'GET_ESSAY_PAGE_RESPONSE',
    GetEssayPageFailed: 'GET_ESSAY_PAGE_FAILED',

    GetEssayRequest: 'GET_ESSAY_REQUEST',
    GetEssayResponse: 'GET_ESSAY_RESPONSE',
    GetEssayFailed: 'GET_ESSAY_FAILED',

    SaveEssayResponse: 'SAVE_ESSAY_RESPONSE',
    RemoveEssayResponse: 'REMOVE_ESSAY_RESPONSE',

    ClearEssay: 'ClearEssay'
}

import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { History } from 'history'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import React from 'react'
import { connect } from 'react-redux'
import { logout } from '@actions/login-action'
import { useConfirmation } from '@components/confirm/confirm-service'
import { withRouter } from 'react-router-dom'

interface ILogoutButtonProps {
    userLogout: (history: History) => void,
    history: History
}
const LogoutButton = (props: ILogoutButtonProps) => {
    const confirm = useConfirmation()

    const userLogout = () => {
        confirm({
          variant: 'danger',
          catchOnCancel: true,
          title: 'Warning',
          description: 'Are you sure you want to logout?'
        }).then(() => {
            props.userLogout(props.history)
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <ListItem button onClick={ userLogout }>
            <ListItemIcon>
                <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={ 'Logout' } />
        </ListItem>
    )
}

export default withRouter(connect(
    null,
    dispatch => ({
        userLogout: (history: History) => dispatch(logout()).then(() => {
            history.push('/login')
        })
    })
)(LogoutButton))

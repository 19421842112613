import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

import Routes from '@components/router/routes'
import grey from '@material-ui/core/colors/grey'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        '@global': {
            html: {
                backgroundColor: grey[900]
            }
        }
    })
)

const App = () => {
    useStyles()

    return (
        <Fragment>
            <Helmet>
                <title>Blog</title>
            </Helmet>
            <Routes />
        </Fragment>
)}

export default App

import Axios from 'axios'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory({
    forceRefresh: true
})

export const setAxiosAuthorization = (token: string) => {
    if (token) {
      Axios.defaults.headers.common.Authorization = `Bearer ${token}`
    } else {
      delete Axios.defaults.headers.common.Authorization
    }
}

export const parseDatetime = (datetime: string) => {
    const d = datetime ? new Date(datetime) : new Date()

    const year = d.getFullYear()
    const month = d.getMonth() + 1
    const day = d.getDate()
    const hours = d.getHours()
    const min = d.getMinutes()
    const seconds = d.getSeconds()

    let result = `${ year }-`
    month < 10 ? result += `0${ month }-` : result += `${ month }-`
    day < 10 ? result += `0${ day }-` : result += `${ day }-`
    hours <= 0 ? result += `0${ hours }-` : result += `${ hours }-`
    min < 10 ? result += `0${ min }-` : result += `${ min }-`
    seconds < 10 ? result += `0${ seconds }-` : result += `${ seconds }-`

    return result


    // let month = String(d.getMonth() + 1)
    // let day = String(d.getDate())
    // let hours = String(d.getHours())
    // let min = String(d.getMinutes())
    // let seconds = String(d.getSeconds())

    
    // if (Number(month) < 10) month = '0' + month;
    // if (Number(day)< 10) day = '0' + day;
    // if (Number(hours) < 0) hours = '0' + hours;
    // if (Number(min) < 10) min = '0' + min;
    // if (Number(seconds) < 10) seconds = '0' + seconds;

    // return (year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + seconds);
}

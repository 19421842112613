import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'

import { ConfirmationServiceProvider } from '@components/confirm/confirm-service'
import LogoutButton from '@components/router/logout-buttton'
import { isAuthenticated } from '@libs/session'
import AppBar from '@material-ui/core/AppBar'
import blue from '@material-ui/core/colors/blue'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuIcon from '@material-ui/icons/Menu'
import MenuBookIcon from '@material-ui/icons/MenuBook'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuButton: {
            marginRight: theme.spacing(2)
        },
        title: {
            flexGrow: 1
        },
        menuList: {
            width: 250
        },
        active: {
            color: blue[500]
        }
    })
)

const PrivateRoute = ({ component, ...rest }: any) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const [drawerState, setDrawerState] = useState(false)

    const toggleDrawer = (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return
        }
        setDrawerState(state)
    }

    const classes = useStyles()

    const { history } = rest
    const currentPage = history.location.pathname.split('/')[1]

    const className = currentPage === 'article' ? classes.active : undefined

    const switchPage = (page: string) => {
        history.push(page)
    }

    const routeComponent = (props: any) => (
        !isEmpty(isAuthenticated())
            ? <>
                <AppBar position="static" color="default">
                    <Toolbar>

                        <>
                            <IconButton
                                className={ classes.menuButton }
                                edge="start"
                                color="inherit"
                                onClick={ toggleDrawer(true) }
                                aria-label="menu">
                                <MenuIcon />
                            </IconButton>
                            <Drawer open={ drawerState } onClose={ toggleDrawer(false) }>
                                <div className={ classes.menuList }>
                                    <List>
                                        <ListItem button onClick={ () => switchPage('/essay') }>
                                            <ListItemIcon>
                                                <MenuBookIcon className={ className } />
                                            </ListItemIcon>
                                            <ListItemText
                                                className = { className }
                                                primary={ 'Essay' } />
                                        </ListItem>
                                    </List>
                                    <Divider />
                                    <List>
                                        <ConfirmationServiceProvider>
                                            <LogoutButton />
                                        </ConfirmationServiceProvider>
                                    </List>
                                </div>
                            </Drawer>
                        </>

                        <Typography variant="h6" className={ classes.title }>
                            Blog-Admin
                        </Typography>

                        <>
                            <IconButton
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              onClick={ handleMenu }
                              color="inherit">
                                <AccountCircle />
                            </IconButton>
                            <Menu
                              id="menu-appbar"
                              anchorEl={ anchorEl }
                              anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                              }}
                              keepMounted
                              transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                              }}
                              open={ open }
                              onClose={ handleClose }>
                                <MenuItem onClick={ handleClose }>Logout</MenuItem>
                            </Menu>
                        </>

                    </Toolbar>
                </AppBar>
                { React.createElement(component, props) }
              </>
            : <Redirect to={'/login'} />
    )

    return <Route { ...rest } render={ routeComponent } />
}

export default withRouter(PrivateRoute)

import { IEssayEditAction, IEssayEditState } from '@libs/interfaces'

import { ActionTypes } from '@libs/constants'

const defaultState: IEssayEditState = {
    id: 0,
    detail: '',
    title: '',
    created_at: ''
}

const essayEdit = (state = defaultState, action: IEssayEditAction ) => {
    switch (action.type) {
        case ActionTypes.ClearEssay:
            return { ...state, ...defaultState}
        case ActionTypes.SaveEssayResponse:
            return { ...state, id: action.id, detail: action.detail, title: action.title, created_at: action.created_at }
        case ActionTypes.GetEssayResponse:
            return { ...state, id: action.id, detail: action.detail, title: action.title, created_at: action.created_at }
        case ActionTypes.EssayDetailEdit:
            return { ...state, detail: action.detail }
        case ActionTypes.EssayTitleEdit:
            return { ...state, title: action.title}
        default:
            return state
    }
}

export default essayEdit
